import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const Portfolio = () => {
  return (
    <Tabs>
      <TabList className="portfolio-tab-list">
        <Tab>Companies</Tab>
      </TabList>

      <div className="portfolio-tab-content">
        <TabPanel>
          <ul className="row grid justify-content-center">
            <li
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="0"
            >
              <a href="http://projectwifi.io">
                <img src="img/projects/ProjectWifi-Banner.png" alt="Portolio" />
                <div className=" hover-content-wrapper"></div>
              </a>
            </li>
            {/* <!-- Portfolio Item Ends --> */}

            {/* <!-- Portfolio Item Starts --> */}
            <li
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              <a href="http://blackwater.co">
                <img src="img/projects/BlackWater-Banner.png" alt="Portolio" />
                <div className=" hover-content-wrapper"></div>
              </a>

              {/* End  ModalTwoContent */}
            </li>
            {/* <!-- Portfolio Item Ends --> */}
          </ul>
        </TabPanel>
        {/* End MOCKUP Project */}
      </div>
    </Tabs>
  );
};

export default Portfolio;
