import React from "react";

const educationContent = [
  {
    year: "2015",
    degree: "Bachelor of science in marketing",
    institute: "University of Texas at Dallas",
    details: ` First generation to attend and complete school.`,
  },
  {
    year: "2016 - 2019",
    degree: "Entreprenuership",
    details: `Pursued entrepreneurship and marketing. During his time in LA, Cantu learned about filming, creating content and building funnels. Marcello learned of his passion for creating content and leveraged it for business. Marcello helped small businesses generate leads via funnels, creatives and running ad campaigns..`,
  },
];

const Education = () => {
  return (
    <ul>
      {educationContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.degree}
            <span className="place open-sans-font">{val.institute}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Education;
