import React from "react";

const experienceContent = [
  {
    year: "   2019 - Present",
    position: " Founder & CEO",
    compnayName: "Project Wifi",
    details: `A management company that creates, modifies, and scales fully functioning E-commerce businesses based in honesty, passion, and innovation.`,
  },
  {
    year: "2021 - Present",
    position: " Founder & CEO",
    compnayName: "BlackWater",
    details: `Blockchain mining company with the intent to make it easy and affordable for others to participate in the growing cryptocurrency industry.`,
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
